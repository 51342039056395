import { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { ItemsByCategory, ShoppingListItem, shoppingListService } from "../../services/ShoppingListService";
import { Category } from "./Category/Category";

export default function ShoppingList() {
  const [items, setItems] = useState<ItemsByCategory[]>([]);
  const [listening, setListening] = useState<boolean>(false);
  const { resetTranscript, finalTranscript } = useSpeechRecognition();

  const toggle = (item: ShoppingListItem) => {
    const updated = {...item};
         updated.collected = !item.collected;

    shoppingListService.toggle(updated);
    setItems(prev => {
      const updatedCategories = prev.map(category => {
        const updatedItems = category.items.map(i => i.id === item.id ? updated : i);
        return {...category, items: updatedItems};
      });
      return updatedCategories;
    });
  }

  const unselectCollected = () => {
    shoppingListService.unselectCollected()
      .then(items => setItems(items));
  }

  useEffect(() => {
    shoppingListService.getList().then((items) => setItems(items));
  }, []);

  useEffect(() => {
    setListening(false);
    if (finalTranscript !== "") {
      shoppingListService
        .aiAdd(finalTranscript)
        .then(updatedItems => {
          console.log('Speach recognized: ' + finalTranscript);
          // console.log('Recognized items: ' + JSON.stringify(updatedItems));
          setItems(updatedItems);
        });
    }
  }, [finalTranscript]);

  const handleListen = () => {
    setListening(true);
    resetTranscript();
    SpeechRecognition.startListening({ language: 'pl-PL' });
  };


  const categories = items
    .map((item) => 
      <Category key={item.category}
                onItemToggle={toggle}
                name={item.category}
                items={item.items}/>);

  return (
    <>
      <div style={{paddingTop: '10px', display: 'inline-flex'}}>
        <div className="btn" onClick={unselectCollected}>Ukryj kupione</div>
        <div className="btn" onClick={handleListen}>{ listening && '🔴'} Dodaj</div>
      </div>
      <pre>{ finalTranscript }</pre>
      {categories}
    </>
  );
}